import ApiService from '@/services/api.service'

export const requestMixin = {
  methods: {
    async request(url, params = {}) {
      try {
        const res = await ApiService.customRequest({
          url,
          ...params,
        });

        return [res.data, null];
      } catch (err) {
        return [null, err];
      }
    },
    getRequestErrorMessage(error) {
      if (!error.response) {
        return error
      }
      
      if (error.response.status === 422) {
        return error.response.data.errors[0].detail;
      } else if (error.response.status === 400) {
        return error.response.data.errors[0].detail;
      } else if (error.response.status === 429) {
        return 'Terlalu banyak permintaan';
      } else {
        return error;
      }
    },
  },
};
